

export const homeMenu = {};

export const dashboardMenu1 = {

	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/astrologer/dashboard.html',
		icon: 'Dashboard',
		subMenu: null,
	},

	wallet: {
		id: 'wallet',
		text: 'Wallet',
		path: '/astrologer/wallet.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	liveGo: {
		id: 'liveGo',
		text: 'Go Live',
		path: '/astrologer/live.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	history: { 
		id: 'history',
		text: 'History',
		path: '#',
		icon: 'Article',
		subMenu: {
			callingHistory: {
				id: 'callingHistory',
				text: 'Calling History',
				path: '/astrologer/calling.html',
				icon: 'ViewArray',
			},
			videoCalling: {
				id: 'videoCalling',
				text: 'Video Calling History',
				path: '/astrologer/video.html',
				icon: 'ViewDay',
			},
			chatHistory: {
				id: 'chatHistory',
				text: 'Chat History',
				path: '/astrologer/chat.html',
				icon: 'ViewDay',
			},
			missedCall: {
				id: 'missedCall',
				text: 'Missed Call History',
				path: '/astrologer/missed-call.html',
				icon: 'ViewDay',
			},
		},
	},
	support: {
		id: 'support',
		text: 'Support',
		path: '/astrologer/support.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	manage_user: { 
		id: 'manage_user',
		text: 'Manage User',
		path: '#',
		icon: 'Article',
		subMenu: {
			followers: {
				id: 'followers',
				text: 'Followers',
				path: '/astrologer/followers.html',
				icon: 'ViewArray',
			},
			blocked_user: {
				id: 'blocked_user',
				text: 'Block List',
				path: '/astrologer/block.html',
				icon: 'ViewDay',
			},
			customer_serv_avl: {
				id: 'customer_serv_avl',
				text: 'Customers',
				path: '/astrologer/customers.html',
				icon: 'ViewDay',
			},
		},
	},
};

export const demoPages = {};

export const layoutMenu = {};

export const componentsMenu1 = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/astrologer/dashboard.html',
		icon: 'Dashboard',
		subMenu: null,
	},

	wallet: {
		id: 'wallet',
		text: 'Wallet',
		path: '/astrologer/wallet.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	
	history: { 
		id: 'history',
		text: 'History',
		path: '#',
		icon: 'Article',
		subMenu: {
			callingHistory: {
				id: 'callingHistory',
				text: 'Calling History',
				path: '/astrologer/calling.html',
				icon: 'ViewArray',
			},
			videoCalling: {
				id: 'videoCalling',
				text: 'Video Calling History',
				path: '/astrologer/video.html',
				icon: 'ViewDay',
			},
			chatHistory: {
				id: 'chatHistory',
				text: 'Chat History',
				path: '/astrologer/chat.html',
				icon: 'ViewDay',
			},
			missedCall: {
				id: 'missedCall',
				text: 'Missed Call History',
				path: '/astrologer/missed-call.html',
				icon: 'ViewDay',
			},
		},
	},
	liveGo: {
		id: 'liveGo',
		text: 'Live Room',
		path: '/astrologer/live.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	support: {
		id: 'support',
		text: 'Support',
		path: '/astrologer/support.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	manage_user: { 
		id: 'manage_user',
		text: 'Manage User',
		path: '#',
		icon: 'Article',
		subMenu: {
			followers: {
				id: 'followers',
				text: 'Followers',
				path: '/astrologer/followers.html',
				icon: 'ViewArray',
			},
			blocked_user: {
				id: 'blocked_user',
				text: 'Block List',
				path: '/astrologer/block.html',
				icon: 'ViewDay',
			},
			customer_serv_avl: {
				id: 'customer_serv_avl',
				text: 'Customers',
				path: '/astrologer/customers.html',
				icon: 'ViewDay',
			},
		},
	},
};

export const productsMenu = {};
