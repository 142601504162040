

export const homeMenu = {};

export const dashboardMenu = {

	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/superadmin/dashboard.html',
		icon: 'Dashboard',
		subMenu: null,
	},

	
	manageAstrologer: { 
		id: 'managea_strologer',
		text: 'Manage Astrologer',
		path: '#',
		icon: 'Article',
		subMenu: {
			AddAstro: {
				id: 'addAstro',
				text: 'Add Astrologer',
				path: '/superadmin/add-astrologer.html',
				icon: 'ViewArray',
			},
			ManageAstro: {
				id: 'manageAstro',
				text: 'Manage Astrologer',
				path: '/superadmin/astrologer.html',
				icon: 'ViewDay',
			},
		},
	},
	wallet: {
		id: 'wallet',
		text: 'Wallet',
		path: '/superadmin/wallet.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	support: {
		id: 'support',
		text: 'Support',
		path: '/superadmin/support.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	package: {
		id: 'package',
		text: 'Package',
		path: '/superadmin/package.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	history: { 
		id: 'history',
		text: 'History',
		path: '#',
		icon: 'Article',
		subMenu: {
			callingHistory: {
				id: 'callingHistory',
				text: 'Calling History',
				path: '/superadmin/calling.html',
				icon: 'ViewArray',
			},
			videoCalling: {
				id: 'videoCalling',
				text: 'Video Calling History',
				path: '/superadmin/video.html',
				icon: 'ViewDay',
			},
			chatHistory: {
				id: 'chatHistory',
				text: 'Chat History',
				path: '/superadmin/chat.html',
				icon: 'ViewDay',
			},
			missedCall: {
				id: 'missedCall',
				text: 'Missed Call History',
				path: '/superadmin/missed-call.html',
				icon: 'ViewDay',
			},
		},
	},

	accounts: { 
		id: 'accounts',
		text: 'Accounts',
		path: '#',
		icon: 'Article',
		subMenu: {
			requestAstroAmount: {
				id: 'requestAstroAmount',
				text: 'Request From Astrologer',
				path: '/superadmin/request-amount.html',
				icon: 'ViewArray',
			},
			tranactins: {
				id: 'tranactins',
				text: 'Complete Trasactions',
				path: '/superadmin/trasections.html',
				icon: 'ViewDay',
			}
		},
	},

	UserAstrologer: { 
		id: 'managea_user',
		text: 'User Management',
		path: '#',
		icon: 'Article',
		subMenu: {
			AddUser: {
				id: 'addUser',
				text: 'Add User',
				path: '/superadmin/add-user.html',
				icon: 'ViewArray',
			},
			ManageUser: {
				id: 'manageUser',
				text: 'Manage Users',
				path: '/superadmin/users.html',
				icon: 'ViewDay',
			},
		},
	},
	persmission: {
		id: 'persmission',
		text: 'Persmission',
		path: '/superadmin/persmission.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	customers: { 
		id: 'customers',
		text: 'Customers Management',
		path: '#',
		icon: 'Article',
		subMenu: {
			AddCustomer: {
				id: 'addCustomer',
				text: 'Add Customer',
				path: '/superadmin/add-customer.html',
				icon: 'ViewArray',
			},
			ManageCustomer: {
				id: 'manageCustomer',
				text: 'Manage Customer',
				path: '/superadmin/customers.html',
				icon: 'ViewDay',
			},
		},
	},
	IntrvForAstro: { 
		id: 'IntrvForAstro',
		text: 'Interviews For Astrologer',
		path: '#',
		icon: 'Article',
		subMenu: {
			category: {
				id: 'category',
				text: 'Category',
				path: '/superadmin/astro-interview-cat.html',
				icon: 'ViewArray',
			},
			sub_category: {
				id: 'sub_category',
				text: 'Sub Category',
				path: '/superadmin/astro-interview-subcat.html',
				icon: 'ViewDay',
			},
			add_int_questions: {
				id: 'add_int_questions',
				text: 'Add Interview Questions',
				path: '/superadmin/add-interview-questions.html',
				icon: 'ViewDay',
			},
			interview_questions: {
				id: 'interview_questions',
				text: 'List Interview Questions',
				path: '/superadmin/interview-questions.html',
				icon: 'ViewDay',
			},
		},
	},
	masters: { 
		id: 'masters',
		text: 'Masters',
		path: '#',
		icon: 'Article',
		subMenu: {
			service: {
				id: 'service',
				text: 'Service Management',
				path: '/superadmin/service.html',
				icon: 'ViewArray',
			},
			language: {
				id: 'language',
				text: 'Language Management',
				path: '/superadmin/language.html',
				icon: 'ViewDay',
			},
			members: {
				id: 'members',
				text: 'All Members',
				path: '/superadmin/members.html',
				icon: 'ViewArray',
			},
			callerDesk: {
				id: 'callerdesk',
				text: 'Caller Desk Member',
				path: '/superadmin/callerdesk-member.html',
				icon: 'ViewDay',
			},
			profile: {
				id: 'profile',
				text: 'Profile Management',
				path: '/superadmin/profile.html',
				icon: 'ViewDay',
			},
		},
	},
};

export const demoPages = {};

export const layoutMenu = {};

export const componentsMenu = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/superadmin/dashboard.html',
		icon: 'Dashboard',
		subMenu: null,
	},
	
	manageAstrologer: { 
		id: 'manage_astrologer',
		text: 'Manage Astrologer',
		path: '#',
		icon: 'Article',
		subMenu: {
			AddAstro: {
				id: 'addAstro',
				text: 'Add Astrologer',
				path: '/superadmin/add-astrologer.html',
				icon: 'ViewArray',
			},
			ManageAstro: {
				id: 'manageAstro',
				text: 'Manage Astrologer',
				path: '/superadmin/astrologer.html',
				icon: 'ViewDay',
			},
		},
	},
	wallet: {
		id: 'wallet',
		text: 'Wallet',
		path: '/superadmin/wallet.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	support: {
		id: 'support',
		text: 'Support',
		path: '/superadmin/support.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	package: {
		id: 'package',
		text: 'Package',
		path: '/superadmin/package.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	history: { 
		id: 'history',
		text: 'History',
		path: '#',
		icon: 'Article',
		subMenu: {
			callingHistory: {
				id: 'callingHistory',
				text: 'Calling History',
				path: '/superadmin/calling.html',
				icon: 'ViewArray',
			},
			videoCalling: {
				id: 'videoCalling',
				text: 'Video Calling History',
				path: '/superadmin/video.html',
				icon: 'ViewDay',
			},
			chatHistory: {
				id: 'chatHistory',
				text: 'Chat History',
				path: '/superadmin/chat.html',
				icon: 'ViewDay',
			},
			missedCall: {
				id: 'missedCall',
				text: 'Missed Call History',
				path: '/superadmin/missed-call.html',
				icon: 'ViewDay',
			},
		},
	},
	UserAstrologer: { 
		id: 'managea_user',
		text: 'User Management',
		path: '#',
		icon: 'Article',
		subMenu: {
			dummy1: {
				id: 'dummy1',
				text: '',
				path: '#',
				icon: 'ViewArray',
			},
			dummy2: {
				id: 'dummy2',
				text: '',
				path: '#',
				icon: 'ViewArray',
			},
			dummy3: {
				id: 'dummy3',
				text: '',
				path: '#',
				icon: 'ViewArray',
			},
			AddUser: {
				id: 'addUser',
				text: 'Add User',
				path: '/superadmin/add-user.html',
				icon: 'ViewArray',
			},
			ManageUser: {
				id: 'manageUser',
				text: 'Manage Users',
				path: '/superadmin/users.html',
				icon: 'ViewDay',
			},
		},
	},
	persmission: {
		id: 'persmission',
		text: 'Persmission',
		path: '/superadmin/persmission.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	customers: { 
		id: 'customers',
		text: 'Customers Management',
		path: '#',
		icon: 'Article',
		subMenu: {
			dummy1: {
				id: 'dummy1',
				text: '',
				path: '#',
				icon: 'ViewArray',
			},
			dummy2: {
				id: 'dummy2',
				text: '',
				path: '#',
				icon: 'ViewArray',
			},
			dummy3: {
				id: 'dummy3',
				text: '',
				path: '#',
				icon: 'ViewArray',
			},
			AddCustomer: {
				id: 'addCustomer',
				text: 'Add Customer',
				path: '/superadmin/add-customer.html',
				icon: 'ViewArray',
			},
			ManageCustomer: {
				id: 'manageCustomer',
				text: 'Manage Customer',
				path: '/superadmin/customers.html',
				icon: 'ViewDay',
			},
		},
	},
	IntrvForAstro: { 
		id: 'IntrvForAstro',
		text: 'Interviews For Astrologer',
		path: '#',
		icon: 'Article',
		subMenu: {
			dummy1: {
				id: 'dummy1',
				text: '',
				path: '#',
				icon: 'ViewArray',
			},
			dummy2: {
				id: 'dummy2',
				text: '',
				path: '#',
				icon: 'ViewArray',
			},
			dummy3: {
				id: 'dummy3',
				text: '',
				path: '#',
				icon: 'ViewArray',
			},
			category: {
				id: 'category',
				text: 'Category',
				path: '/superadmin/astro-interview-cat.html',
				icon: 'ViewArray',
			},
			sub_category: {
				id: 'sub_category',
				text: 'Sub Category',
				path: '/superadmin/astro-interview-subcat.html',
				icon: 'ViewDay',
			},
			add_int_questions: {
				id: 'add_int_questions',
				text: 'Add Interview Questions',
				path: '/superadmin/add-interview-questions.html',
				icon: 'ViewDay',
			},
			interview_questions: {
				id: 'interview_questions',
				text: 'List Interview Questions',
				path: '/superadmin/interview-questions.html',
				icon: 'ViewDay',
			},
		},
	},
	masters: { 
		id: 'masters',
		text: 'Masters',
		path: '#',
		icon: 'Article',
		subMenu: {
			dummy1: {
				id: 'dummy1',
				text: '',
				path: '#',
				icon: 'ViewArray',
			},
			dummy2: {
				id: 'dummy2',
				text: '',
				path: '#',
				icon: 'ViewArray',
			},
			dummy3: {
				id: 'dummy3',
				text: '',
				path: '#',
				icon: 'ViewArray',
			},
			service: {
				id: 'service',
				text: 'Service Management',
				path: '/superadmin/service.html',
				icon: 'ViewArray',
			},
			language: {
				id: 'language',
				text: 'Language Management',
				path: '/superadmin/language.html',
				icon: 'ViewDay',
			},
			callerDesk: {
				id: 'callerdesk',
				text: 'Caller Desk Member',
				path: '/superadmin/callerdesk-member.html',
				icon: 'ViewDay',
			},
			profile: {
				id: 'profile',
				text: 'Profile Management',
				path: '/superadmin/profile.html',
				icon: 'ViewDay',
			},
		},
	},
};

export const productsMenu = {};
