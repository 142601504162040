import React, { lazy } from 'react';
import { dashboardMenu } from '../menu';
import { dashboardMenu1 } from '../menu_astro';
import { dashboardMenu2 } from '../menu_customer';
import Login from '../pages/presentation/auth/Login';
import Loginweb from '../pages/Loginweb';

const LANDING = {
	DASHBOARD: lazy(() => import('../astro/superadmin/dashboard')),
	
	AddAstro: lazy(() => import('../astro/superadmin/astrologer/add_astro')),
	ManageAstro: lazy(() => import('../astro/superadmin/astrologer/view_astro')),
	EditAstro: lazy(() => import('../astro/superadmin/astrologer/edit_astro')),
	AstroInterview: lazy(() => import('../astro/superadmin/astrologer/astro_interview')),
	AstroInterviewResult: lazy(() => import('../astro/superadmin/astrologer/astro_interview_result')),
	UpdateKyc: lazy(() => import('../astro/superadmin/astrologer/UpdateKyc')),
	
	
	Wallet: lazy(() => import('../astro/superadmin/wallet/wallet')),
	
	Support: lazy(() => import('../astro/superadmin/support/Support')),
	AddSupport: lazy(() => import('../astro/superadmin/support/AddSupport')),
	EditSupport: lazy(() => import('../astro/superadmin/support/EditSupport')),
	SupportLog: lazy(() => import('../astro/superadmin/support/SupportLog')),

	Package: lazy(() => import('../astro/superadmin/package/Package')),
	AddPackage: lazy(() => import('../astro/superadmin/package/AddPackage')),
	EditPackage: lazy(() => import('../astro/superadmin/package/EditPackage')),

	callingHistory: lazy(() => import('../astro/superadmin/history/CallingHistory')),

	Users: lazy(() => import('../astro/superadmin/user/Users')),
	AddUser: lazy(() => import('../astro/superadmin/user/AddUser')),
	EditUser: lazy(() => import('../astro/superadmin/user/EditUser')),

	Customers: lazy(() => import('../astro/superadmin/customer/Customers')),
	AddCustomer: lazy(() => import('../astro/superadmin/customer/AddCustomer')),
	EditCustomer: lazy(() => import('../astro/superadmin/customer/EditCustomer')),

	InterviewCategory: lazy(() => import('../astro/superadmin/interview_astro/category/InterviewCategory')),
	EditInterviewCategory: lazy(() => import('../astro/superadmin/interview_astro/category/EditInterviewCategory')),

	InterviewSubCategory: lazy(() => import('../astro/superadmin/interview_astro/subcategory/InterviewSubCategory')),
	EditInterviewSubCategory: lazy(() => import('../astro/superadmin/interview_astro/subcategory/EditInterviewSubCategory')),
	
	InterviewAdd: lazy(() => import('../astro/superadmin/interview_astro/InterviewAdd')),
	Interview: lazy(() => import('../astro/superadmin/interview_astro/Interview')),
	EditInterview: lazy(() => import('../astro/superadmin/interview_astro/EditInterview')),
	
	Profile: lazy(() => import('../astro/superadmin/setting/Profile')),
	Service: lazy(() => import('../astro/superadmin/setting/service/Service')),
	Langauge: lazy(() => import('../astro/superadmin/setting/language/Language')),
	EditService: lazy(() => import('../astro/superadmin/setting/service/EditService')),
	EditLanguage: lazy(() => import('../astro/superadmin/setting/language/EditLanguage')),
	

	callerdeskMember: lazy(() => import('../astro/superadmin/setting/callerdesk/List')),
	members: lazy(() => import('../astro/superadmin/setting/Members')),

	requestAmount: lazy(() => import('../astro/superadmin/accounts/RequestAmount')),
	
};

const Astro = {
	DASHBOARD: lazy(() => import('../astro/astrologer/dashboard')),
	Wallet: lazy(() => import('../astro/astrologer/wallet/Wallet')),

	callingHistory: lazy(() => import('../astro/astrologer/history/CallingHistory')),

	liveGo: lazy(() => import('../astro/astrologer/live/GoLive')),
	ChatAstro: lazy(() => import('../astro/astrologer/live/ChatPage')),
	SingleChatAstro: lazy(() => import('../astro/astrologer/live/SingleChatPage')),
	

	Support: lazy(() => import('../astro/astrologer/support/Support')),
	AddSupport: lazy(() => import('../astro/astrologer/support/AddSupport')),
	EditSupport: lazy(() => import('../astro/astrologer/support/EditSupport')),
	SupportLog: lazy(() => import('../astro/astrologer/support/SupportLog')),

	blockUser: lazy(() => import('../astro/astrologer/users/Block')),
	custList: lazy(() => import('../astro/astrologer/users/Customers')),
	followers: lazy(() => import('../astro/astrologer/users/Followers')),

}

const CustomerLogin = {
	DASHBOARD: lazy(() => import('../astro/customer/dashboard')),
	Wallet: lazy(() => import('../astro/customer/wallet/Wallet')),
	Live: lazy(() => import('../astro/customer/live/Live')),
	Chat: lazy(() => import('../astro/customer/live/ChatPage')),

	callingHistory: lazy(() => import('../astro/customer/history/CallingHistory')),

	Support: lazy(() => import('../astro/customer/support/Support')),
	AddSupport: lazy(() => import('../astro/customer/support/AddSupport')),
	EditSupport: lazy(() => import('../astro/customer/support/EditSupport')),
	SupportLog: lazy(() => import('../astro/customer/support/SupportLog')),

	
}

const AUTH = {
	PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};


const presentation = [
	{
		path: dashboardMenu.dashboard.path,
		element: <LANDING.DASHBOARD />,
		exact: true,
	},
	// Astologer
	{
		path: dashboardMenu.manageAstrologer.subMenu.AddAstro.path,
		element: <LANDING.AddAstro />,
		exact: true,
	}, 
	{
		path: dashboardMenu.manageAstrologer.subMenu.ManageAstro.path,
		element: <LANDING.ManageAstro />,
		exact: true,
	}, 
	{
		path: '/superadmin/edit-astrologer/:id',
		element: <LANDING.EditAstro />,
		exact: true,
	}, 
	{
		path: '/superadmin/astro-interview/:id',
		element: <LANDING.AstroInterview />,
		exact: true,
	}, 
	{
		path: '/superadmin/astro-interview-result/:id',
		element: <LANDING.AstroInterviewResult />,
		exact: true,
	},
	{
		path: '/superadmin/update-kyc/:id',
		element: <LANDING.UpdateKyc />,
		exact: true,
	},
	// Wallet
	{
		path: dashboardMenu.wallet.path,
		element: <LANDING.Wallet />,
		exact: true,
	}, 
	// Support
	{
		path: dashboardMenu.support.path,
		element: <LANDING.Support />,
		exact: true,
	}, 
	{
		path: '/superadmin/add-support.html',
		element: <LANDING.AddSupport />,
		exact: true,
	}, 
	{
		path: '/superadmin/edit-support/:id',
		element: <LANDING.EditSupport />,
		exact: true,
	},
	{
		path: '/superadmin/support-log/:id',
		element: <LANDING.SupportLog />,
		exact: true,
	},
	// Package
	{
		path: dashboardMenu.package.path,
		element: <LANDING.Package />,
		exact: true,
	}, 
	{
		path: '/superadmin/add-package.html',
		element: <LANDING.AddPackage />,
		exact: true,
	}, 
	{
		path: '/superadmin/edit-package/:id',
		element: <LANDING.EditPackage />,
		exact: true,
	},
	// History
	{
		path: dashboardMenu.history.subMenu.callingHistory.path,
		element: <LANDING.callingHistory />,
		exact: true,
	},
	// User ==
	{
		path: dashboardMenu.UserAstrologer.subMenu.ManageUser.path,
		element: <LANDING.Users />,
		exact: true,
	}, 
	{
		path: dashboardMenu.UserAstrologer.subMenu.AddUser.path,
		element: <LANDING.AddUser />,
		exact: true,
	}, 
	{
		path: '/superadmin/edit-user/:id',
		element: <LANDING.EditUser />,
		exact: true,
	},

	// Customers ==
	{
		path: dashboardMenu.customers.subMenu.ManageCustomer.path,
		element: <LANDING.Customers />,
		exact: true,
	}, 
	{
		path: dashboardMenu.customers.subMenu.AddCustomer.path,
		element: <LANDING.AddCustomer />,
		exact: true,
	}, 
	{
		path: '/superadmin/edit-customer/:id',
		element: <LANDING.EditCustomer />,
		exact: true,
	},

	// Interview master
	{
		path: dashboardMenu.IntrvForAstro.subMenu.category.path,
		element: <LANDING.InterviewCategory />,
		exact: true,
	}, 
	{
		path: dashboardMenu.IntrvForAstro.subMenu.sub_category.path,
		element: <LANDING.InterviewSubCategory />,
		exact: true,
	}, 
	{
		path: dashboardMenu.IntrvForAstro.subMenu.add_int_questions.path,
		element: <LANDING.InterviewAdd />,
		exact: true,
	}, 
	{
		path: dashboardMenu.IntrvForAstro.subMenu.interview_questions.path,
		element: <LANDING.Interview />,
		exact: true,
	}, 
	{
		path: '/superadmin/edit-interview-cat/:id',
		element: <LANDING.EditInterviewCategory />,
		exact: true,
	},
	{
		path: '/superadmin/edit-interview-subcat/:id',
		element: <LANDING.EditInterviewSubCategory />,
		exact: true,
	},
	{
		path: '/superadmin/edit-interview/:id',
		element: <LANDING.EditInterview />,
		exact: true,
	},

	// Masters
	{
		path: dashboardMenu.masters.subMenu.service.path,
		element: <LANDING.Service />,
		exact: true,
	}, 
	{
		path: dashboardMenu.masters.subMenu.language.path,
		element: <LANDING.Langauge />,
		exact: true,
	}, 
	{
		path: dashboardMenu.masters.subMenu.callerDesk.path,
		element: <LANDING.callerdeskMember />,
		exact: true,
	}, 

	{
		path: dashboardMenu.masters.subMenu.members.path,
		element: <LANDING.members />,
		exact: true,
	}, 

	{
		path: dashboardMenu.accounts.subMenu.requestAstroAmount.path,
		element: <LANDING.requestAmount />,
		exact: true,
	}, 
	
	
	{
		path: dashboardMenu.masters.subMenu.profile.path,
		element: <LANDING.Profile />,
		exact: true,
	}, 
	{
		path: '/superadmin/edit-service/:id',
		element: <LANDING.EditService />,
		exact: true,
	},
	{
		path: '/superadmin/edit-language/:id',
		element: <LANDING.EditLanguage />,
		exact: true,
	},
	

	/** ************************************************** */
	
	{
		path: '/',
		element: <Login />,
		exact: true,
	},

	{
		path: '/web/:phone/:otp',
		element: <Loginweb />,
		exact: true,
	},


	//======== Astologer Menu........
	{
		path: dashboardMenu1.dashboard.path,
		element: <Astro.DASHBOARD />,
		exact: true,
	},

	{
		path: dashboardMenu1.wallet.path,
		element: <Astro.Wallet />,
		exact: true,
	},
	{
		path: dashboardMenu1.history.subMenu.callingHistory.path,
		element: <Astro.callingHistory />,
		exact: true,
	},

	{
		path: dashboardMenu1.liveGo.path,
		element: <Astro.liveGo />,
		exact: true,
	},

	// Support
	{
		path: dashboardMenu1.support.path,
		element: <Astro.Support />,
		exact: true,
	}, 
	{
		path: '/astrologer/add-support.html',
		element: <Astro.AddSupport />,
		exact: true,
	}, 
	{
		path: '/astrologer/edit-support/:id',
		element: <Astro.EditSupport />,
		exact: true,
	},
	{
		path: '/astrologer/support-log/:id',
		element: <Astro.SupportLog />,
		exact: true,
	},

	// Manage User
	{
		path: dashboardMenu1.manage_user.subMenu.followers.path,
		element: <Astro.followers />,
		exact: true,
	},
	{
		path: dashboardMenu1.manage_user.subMenu.blocked_user.path,
		element: <Astro.blockUser />,
		exact: true,
	},
	{
		path: dashboardMenu1.manage_user.subMenu.customer_serv_avl.path,
		element: <Astro.custList />,
		exact: true,
	},

	{
		path: '/astrologer/chat.html',
		element: <Astro.ChatAstro />,
		exact: true,
	},
	{
		path: '/astrologer/chat/:id',
		element: <Astro.SingleChatAstro />,
		exact: true,
	},


	//======== Customer Menu........
	{
		path: dashboardMenu2.dashboard.path,
		element: <CustomerLogin.DASHBOARD />,
		exact: true,
	},

	{
		path: dashboardMenu2.wallet.path,
		element: <CustomerLogin.Wallet />,
		exact: true,
	},
	{
		path: dashboardMenu2.liveCall.path,
		element: <CustomerLogin.Live />,
		exact: true,
	},
	{
		path: dashboardMenu2.history.subMenu.callingHistory.path,
		element: <CustomerLogin.callingHistory />,
		exact: true,
	},

	// Support
	{
		path: dashboardMenu2.support.path,
		element: <CustomerLogin.Support />,
		exact: true,
	}, 
	{
		path: '/customer/add-support.html',
		element: <CustomerLogin.AddSupport />,
		exact: true,
	}, 
	{
		path: '/customer/edit-support/:id',
		element: <CustomerLogin.EditSupport />,
		exact: true,
	},
	{
		path: '/customer/support-log/:id',
		element: <CustomerLogin.SupportLog />,
		exact: true,
	},
	{
		path: '/customer/chat/:id',
		element: <CustomerLogin.Chat />,
		exact: true,
	},
	


];
const documentation = [];
const contents = [...presentation];
export default contents;
