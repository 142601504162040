import React, { FC, useCallback, useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Input from '../../../components/bootstrap/forms/Input';
import Button from '../../../components/bootstrap/Button';
import Logo from '../../../components/Logo';
import useDarkMode from '../../../hooks/useDarkMode';
import { useFormik } from 'formik';
import AuthContext from '../../../contexts/authContext';
import USERS, { getUserDataWithUsername } from '../../../common/data/userDummyData';
import Spinner from '../../../components/bootstrap/Spinner';
import Alert from '../../../components/bootstrap/Alert';
import logos from '../../../assets/astroaruna.png';
import axios from 'axios';

interface ILoginHeaderProps {
	isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = () => {
	return (
		<>
			<div className='text-center h1 fw-bold mt-5'>Welcome,</div>
			<div className='text-center h4 text-muted mb-5'>Sign in to continue!</div>
		</>
	);
	
};

interface ILoginProps {
	isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
	const [user, setUser] = useState({
		userid: "",
		user_pass: ""
	});

	async function onTextFieldChange(e : any) {
		setUser({
		 ...user,
		 [e.target.name]: e.target.value
		})
	}

	async function onFormSubmit(e :any) {
		e.preventDefault()
		try {
		  axios.post(`https://backend.dailykundli.com/api/login`, user)
		 .then((res) => {
			const LoginData = res.data;
      		if(LoginData == "Invalid"){
				alert("Invalid Login Details !!!")
				window.scrollTo({ top: 0, behavior: 'smooth' });
			} else {
				localStorage.setItem('sess_id', LoginData.id);
				localStorage.setItem('sess_name', LoginData.name);
				localStorage.setItem('sess_email', LoginData.email);
				localStorage.setItem('sess_phone', LoginData.phone);
				localStorage.setItem('sess_api_key', LoginData.api_key);
				localStorage.setItem('sess_userid', LoginData.userid);
				localStorage.setItem('sess_userimg', LoginData.userimg);
				localStorage.setItem('sess_user_type', LoginData.user_type);
				window.location.assign(LoginData.user_type+'/dashboard.html');
			}
		 });
		} catch (error) {
			alert("Something is Wrong");
		 	window.scrollTo({ top: 0, behavior: 'smooth' });
		}
		 
	}
	

	const { darkModeStatus } = useDarkMode();

	const [signInPassword, setSignInPassword] = useState<boolean>(false);
	const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);

	const navigate = useNavigate();
	const handleOnClick = useCallback(() => navigate('/'), [navigate]);

	
	
	return (
		<PageWrapper
			isProtected={false}
			title="Login"
			className={classNames({ 'bg-warning': !singUpStatus, 'bg-info': singUpStatus })}>
			<Page className='p-0'>
				<div className='row h-100 align-items-center justify-content-center'>
					<div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
						<Card className='shadow-3d-dark' data-tour='login-page'>
							<CardBody>
								<div className='text-center my-5'>
									<Link
										to='/'
										className={classNames(
											'text-decoration-none  fw-bold display-2',
											{
												'text-dark': !darkModeStatus,
												'text-light': darkModeStatus,
											},
										)}>
										<img src={logos} width={260} />
									</Link>
								</div>
								<div
									className={classNames('rounded-3', {
										'bg-l10-dark': !darkModeStatus,
										'bg-dark': darkModeStatus,
									})}>
									
								</div>

								<LoginHeader isNewUser={singUpStatus} />

								<form className='row g-4'>
									<div className='col-12'>
										<FormGroup
											id='signup-userid'
											isFloating
											label='Your User Id'>
											<Input 
												type='text' 
												autoComplete='userid' 
												name='userid'
												id='userid'
												value={user.userid} 
												onChange={e => onTextFieldChange(e)} 
												/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup
											id='signup-password'
											isFloating
											label='Password'>
											<Input
												type='password'
												autoComplete='password'
												name='user_pass'
												id='user_pass'
												value={user.user_pass} onChange={e => onTextFieldChange(e)}
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<Button
											color='info'
											className='w-100 py-3'
											onClick={e => onFormSubmit(e)}>
											Log In
										</Button>
									</div>


									

									{/* BEGIN :: Social Login */}
									{!signInPassword && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>
												OR
											</div>
											<div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													Sign in with Apple
												</Button>
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													Continue with Google
												</Button>
											</div>
										</>
									)}
									{/* END :: Social Login */}
								</form>
							</CardBody>
						</Card>
						<div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none', {
									'link-light': singUpStatus,
									'link-dark': !singUpStatus,
								})}>
								Terms of use
							</a>
						</div>
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};


export default Login;
