

export const homeMenu = {};

export const dashboardMenu2 = {

	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/customer/dashboard.html',
		icon: 'Dashboard',
		subMenu: null,
	},

	wallet: {
		id: 'wallet',
		text: 'Wallet',
		path: '/customer/wallet.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	liveCall: {
		id: 'liveCall',
		text: 'Live Room',
		path: '/customer/live.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	history: { 
		id: 'history',
		text: 'History',
		path: '#',
		icon: 'Article',
		subMenu: {
			callingHistory: {
				id: 'callingHistory',
				text: 'Calling History',
				path: '/customer/calling.html',
				icon: 'ViewArray',
			},
			videoCalling: {
				id: 'videoCalling',
				text: 'Video Calling History',
				path: '/customer/video.html',
				icon: 'ViewDay',
			},
			chatHistory: {
				id: 'chatHistory',
				text: 'Chat History',
				path: '/customer/chat.html',
				icon: 'ViewDay',
			},
			missedCall: {
				id: 'missedCall',
				text: 'Missed Call History',
				path: '/customer/missed-call.html',
				icon: 'ViewDay',
			},
		},
	},
	support: {
		id: 'support',
		text: 'Support',
		path: '/customer/support.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	package: {
		id: 'package',
		text: 'Package',
		path: '/customer/package.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
};

export const demoPages = {};

export const layoutMenu = {};

export const componentsMenu2 = {
	dashboard: {
		id: 'dashboard',
		text: 'Dashboard',
		path: '/customer/dashboard.html',
		icon: 'Dashboard',
		subMenu: null,
	},

	wallet: {
		id: 'wallet',
		text: 'Wallet',
		path: '/customer/wallet.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	
	history: { 
		id: 'history',
		text: 'History',
		path: '#',
		icon: 'Article',
		subMenu: {
			callingHistory: {
				id: 'callingHistory',
				text: 'Calling History',
				path: '/customer/calling.html',
				icon: 'ViewArray',
			},
			videoCalling: {
				id: 'videoCalling',
				text: 'Video Calling History',
				path: '/customer/video.html',
				icon: 'ViewDay',
			},
			chatHistory: {
				id: 'chatHistory',
				text: 'Chat History',
				path: '/customer/chat.html',
				icon: 'ViewDay',
			},
			missedCall: {
				id: 'missedCall',
				text: 'Missed Call History',
				path: '/customer/missed-call.html',
				icon: 'ViewDay',
			},
		},
	},
	liveCall: {
		id: 'liveCall',
		text: 'Live Room',
		path: '/customer/live.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	support: {
		id: 'support',
		text: 'Support',
		path: '/customer/support.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
	package: {
		id: 'package',
		text: 'Package',
		path: '/customer/package.html',
		icon: 'sticky_note_2',
		subMenu: null,
	},
};

export const productsMenu = {};
