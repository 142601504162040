import React from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import Navigation from '../../../layout/Navigation/Navigation';
import { componentsMenu, layoutMenu } from '../../../menu';
import { componentsMenu1 } from '../../../menu_astro';
import { componentsMenu2 } from '../../../menu_customer';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import CommonHeaderRight from './CommonHeaderRight';

const sessUserType = localStorage.getItem('sess_user_type');

const DefaultHeader = () => {
	const { width } = useDeviceScreen();
	return (
		<Header>
			<HeaderLeft>
				{ sessUserType == "superadmin" && (
					<Navigation
						menu={{ ...layoutMenu, ...componentsMenu }}
						id='header-top-menu'
						horizontal={
							!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
						}
					/>
				)}
				
				{ sessUserType == "astrologer" && (
					<Navigation
						menu={{ ...layoutMenu, ...componentsMenu1 }}
						id='header-top-menu'
						horizontal={
							!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
						}
					/>
				)}

				{ sessUserType == "customer" && (
					<Navigation
						menu={{ ...layoutMenu, ...componentsMenu2 }}
						id='header-top-menu'
						horizontal={
							!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
						}
					/>
				)}
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default DefaultHeader;
