import React, { useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import PageWrapper from '../layout/PageWrapper/PageWrapper';
import Page from '../layout/Page/Page';
import useMinimizeAside from '../hooks/useMinimizeAside';



const Loginweb = () => {
	useMinimizeAside();
    var { phone } = useParams();
    var { otp } = useParams();

	useEffect(() => {

		async function loginWebFn() {
            
			try {
                axios.post(`https://backend.dailykundli.com/api/login_with_otp_back/${phone}/${otp}`)
                .then((res) => {
                    const LoginData = res.data;
                    if(LoginData == "Invalid"){
                        alert("Invalid Login Details !!!");
                    } else {
                        localStorage.setItem('sess_id', LoginData.id);
                        localStorage.setItem('sess_name', LoginData.name);
                        localStorage.setItem('sess_email', LoginData.email);
                        localStorage.setItem('sess_phone', LoginData.phone);
                        localStorage.setItem('sess_api_key', LoginData.api_key);
                        localStorage.setItem('sess_userid', LoginData.userid);
                        localStorage.setItem('sess_userimg', LoginData.userimg);
                        localStorage.setItem('sess_user_type', LoginData.user_type);
                        window.location.assign('../../'+LoginData.user_type+'/dashboard.html');
                    }
                });
            } catch (error) {
                alert("Something is Wrong");
            }
		}

		loginWebFn();
	}, []);


	

	return (
		<PageWrapper title="login">
			<Page>
                <div className='text-center'>
                    <div className="loader"></div>
                </div>
			</Page>
		</PageWrapper>
	);
};

export default Loginweb;



